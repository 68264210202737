import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../../../../src/common/components/Layout'
import { SeoData } from '../../../../src/common/types/Seo'
import { t } from '../../../../src/common/i18n/index'
import styles from './index.module.scss'
import { useSelectedMenu } from '../../../../src/specific/helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../../../src/specific/constants/menu'

interface Props {
  data: {
    seoData: SeoData
  }
}
const patrocinios = ({ data }: Props) => {
  useSelectedMenu(MenuPrincipal.HOME)
  return (
    <Layout selector={false} seoData={data.seoData}>
      <div className={styles.patrocinio}>
        <div className={styles.patrocinio_title_cont}>
          <h1 className={styles.patrocinio_title}>{t('patrocinios.titulo')}</h1>
        </div>

        <div className={styles.patrocinio_title_cont}>
          <h2 className={styles.patrocinio_subtitle}>
            {t('patrocinios.bloque2.titulo1')}
          </h2>

          <div className={styles.patrocinio_cont_images}>
            <div className={styles.patrocinio_images}>
              <img
                src={'https://media.rodi.es/estaticas/patrocinios/mitja_1.jpg'}
                alt="RODI MITJA MARATÓ"
                title="RODI MITJA MARATÓ"
                width="100%"
              />
            </div>
            <div className={styles.patrocinio_images}>
              <img
                src={'https://media.rodi.es/estaticas/patrocinios/mitja_2.jpg'}
                alt="RODI MITJA MARATÓ"
                title="RODI MITJA MARATÓ"
                width="100%"
              />
            </div>
          </div>
          <div className={styles.patrocinio_text_section}>
            <div className={styles.patrocinio_column_section}>
              <div className={styles.patrocinio_column}>
                <p className={styles.patrocinio_text_column}>
                  {t('patrocinios.bloque2.parte1.texto1')}
                  <strong>{t('patrocinios.bloque2.parte1.texto2')}</strong>
                  {t('patrocinios.bloque2.parte1.texto3')}
                </p>
              </div>
              <div className={styles.patrocinio_column}>
                <p className={styles.patrocinio_text_column}>
                  {t('patrocinios.bloque2.parte2.texto1')}
                  <strong>{t('patrocinios.bloque2.parte2.texto2')}</strong>
                  {t('patrocinios.bloque2.parte2.texto3')}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.patrocinio_title_cont}>
          <h2 className={styles.patrocinio_subtitle}>
            {t('patrocinios.bloque3.titulo1')}
          </h2>

          <div className={styles.patrocinio_cont_images}>
            <div className={styles.patrocinio_images}>
              <img
                src={'https://media.rodi.es/estaticas/patrocinios/volei_1.jpg'}
                alt="RODI BALÀFIA VOLEI"
                title="RODI BALÀFIA VOLEI"
                width="100%"
              />
            </div>
            <div className={styles.patrocinio_images}>
              <img
                src={'https://media.rodi.es/estaticas/patrocinios/volei_2.jpg'}
                alt="RODI BALÀFIA VOLEI"
                title="RODI BALÀFIA VOLEI"
                width="100%"
              />
            </div>
          </div>
          <div className={styles.patrocinio_text_section}>
            <div className={styles.patrocinio_column_section}>
              <div className={styles.patrocinio_column}>
                <p className={styles.patrocinio_text_column}>
                  {t('patrocinios.bloque3.parte1.texto1')}
                  <strong>{t('patrocinios.bloque3.parte1.texto2')}</strong>
                  {t('patrocinios.bloque3.parte1.texto3')}
                </p>
              </div>
              <div className={styles.patrocinio_column}>
                <p className={styles.patrocinio_text_column}>
                  {t('patrocinios.bloque3.parte2.texto1')}
                  <strong>{t('patrocinios.bloque3.parte2.texto2')}</strong>
                  {t('patrocinios.bloque3.parte2.texto3')}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.patrocinio_title_cont}>
          <h2 className={styles.patrocinio_subtitle}>
            {t('patrocinios.bloque4.titulo1')}
          </h2>

          <div className={styles.patrocinio_cont_images}>
            <div className={styles.patrocinio_images}>
              <img
                src={
                  'https://media.rodi.es/estaticas/patrocinios/equipo-femenino-esqui-alpino-pekin-22.jpg'
                }
                alt="RODI ESQUÍ ALPINO FEMENINO"
                title="RODI ESQUÍ ALPINO FEMENINO"
                width="100%"
              />
            </div>
            <div className={styles.patrocinio_images}>
              <img
                src={
                  'https://media.rodi.es/estaticas/patrocinios/equipo-femenino-esqui-alpino-pekin-22-alex-marquez.jpg'
                }
                alt="RODI ESQUÍ ALPINO FEMENINO"
                title="RODI ESQUÍ ALPINO FEMENINO"
                width="100%"
              />
            </div>
          </div>
          <div className={styles.patrocinio_text_section}>
            <div className={styles.patrocinio_column_section}>
              <div className={styles.patrocinio_column}>
                <p className={styles.patrocinio_text_column}>
                  {t('patrocinios.bloque4.parte1.texto1')}
                </p>
              </div>
              <div className={styles.patrocinio_column}>
                <p className={styles.patrocinio_text_column}>
                  {t('patrocinios.bloque4.parte2.texto1')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default patrocinios

export const pageQuery = graphql`
  query patrociniosQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
